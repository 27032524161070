import { CampaignOutlined, DataUsageOutlined, WorkspacesOutlined } from "@mui/icons-material";
import { Box, Divider, Grid, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseFeatureCalloutCard } from "@safelease/components";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";
import GoogleLogo from "../assets/google-logo.png";
import { RatingsDisplay } from "../components/RatingsDisplay";
import { useEffect } from "react";
import RouterHelper from "../../utilities/router-helper";
import RelationshipSelector from "../../shared/RelationshipSelector";

import { useCalendlyEventListener } from "react-calendly";
import { QueryResult, useQuery } from "@apollo/client";
import { Relationship, Location } from "../../utilities/generated/gql-types";
import { getLocations, getRelationship } from "../../queries";
import { useAuth } from "../../auth";
import { CalendlyButton } from "../../shared/CalendlyButton";
import { PartnerActivation } from "../components/PartnerActivation/PartnerActivationCard";
import { useHistory } from "react-router-dom";
import { AdminsOnly } from "../components/AdminsOnly";

interface ReputationPreActivationPageProps {
  routerHelper: RouterHelper;
}

export function ReputationPreActivationPage({ routerHelper }: ReputationPreActivationPageProps) {
  const selectedRelationshipId = routerHelper.getRelationshipId();
  const { push: navigate } = useHistory();
  const auth = useAuth();
  const isAdmin = auth.user?.role === "admin";

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(getRelationship, {
    variables: {
      id: selectedRelationshipId,
    },
    skip: !selectedRelationshipId,
  });

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: {
      relationshipId: selectedRelationshipId,
    },
    skip: !selectedRelationshipId,
  });

  useEffect(() => {
    mixpanelEventHandler("Reputation - Page View - Pre-Setup");
  }, []);

  /* Mixpanel / Calendly events */
  useCalendlyEventListener({
    onDateAndTimeSelected: () =>
      mixpanelEventHandler("Reputation - Calendly Date Selected", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        selectedRelationshipId,
      }),
    onEventTypeViewed: () =>
      mixpanelEventHandler("Reputation - Calendly Event Type Viewed", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        selectedRelationshipId,
      }),
    onEventScheduled: (e) =>
      mixpanelEventHandler("Reputation - Calendly Event Scheduled", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        selectedRelationshipId,
      }),
  });

  const handleRelationshipChange = (inputRelationshipId: string) => {
    if (isAdmin) {
      routerHelper.setRelationshipId(inputRelationshipId);
      navigate(`/admin/reputation/${inputRelationshipId}`);
    } else {
      routerHelper.setRelationshipId(inputRelationshipId);
      navigate(`/reputation`);
    }
  };

  return (
    <Grid container spacing={4} sx={{ maxWidth: (theme: Theme) => theme.breakpoints.values.xl }}>
      <Grid item xs={12}>
        <Typography variant="h4">Reputation Made Easy</Typography>
        <Typography>
          SafeLease Reputation is a powerful tool that helps you manage your online reputation. Get more reviews, manage your reviews, and
          gain insights into what your tenants are saying about you online.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <SafeLeaseFeatureCalloutCard
            sx={{ width: 240 }}
            title="Brand Reputation"
            description="View and manage reviews across your portfolio & individual facilities."
            icon={<WorkspacesOutlined />}
          />
          <SafeLeaseFeatureCalloutCard
            sx={{ width: 240 }}
            title="Actionable Insights"
            description="Gain insight into what your tenants are saying and where to improve."
            icon={<DataUsageOutlined />}
          />

          <SafeLeaseFeatureCalloutCard
            sx={{ width: 240 }}
            title="Campaigns & Tenants"
            description="Elicit reviews with campaigns and manage contacts."
            icon={<CampaignOutlined />}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <CalendlyButton
              relationshipId={selectedRelationshipId}
              variant="filled"
              label="Learn More"
              linkName="reputation"
              sx={{ minWidth: 0 }}
            />
            <RelationshipSelector
              routerHelper={routerHelper}
              nonRoute
              nonRouteCallback={(relationshipId: string) => handleRelationshipChange(relationshipId)}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={GoogleLogo} alt="Google Logo" style={{ height: 20, width: "auto" }} />
              <Typography variant="body2">Google</Typography>
            </Stack>
            <Typography variant="body2" color="grey.A100" sx={{ mt: 1 }}>
              Locations below are from reviews sites associated with your profile
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <RatingsDisplay averageRating={4.5} previousRating={4} ratings={{ 1: 10, 2: 8, 3: 15, 4: 16, 5: 85 }} />
      </Grid>
      <AdminsOnly>
        <Grid item xs={12}>
          <PartnerActivation relationship={relationshipQuery?.data?.getRelationship} locations={locationsQuery?.data?.getLocations} />
        </Grid>
      </AdminsOnly>
    </Grid>
  );
}
