import { CircularProgress, Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { Relationship, Location } from "../../../utilities/generated/gql-types";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import { useReputation } from "../../useReputation";
import { enqueueSnackbar } from "notistack";

interface PartnerActivationProps {
  relationship: Relationship;
  locations: Location[];
  collapsed?: boolean;
}

export const PartnerActivation = ({ relationship, locations }: PartnerActivationProps) => {
  const { SafeLeaseAPI } = useReputation();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

  if (!relationship || !locations || locations.length === 0) return <></>;

  const handleCheckboxChange = (locationId: string) => {
    setSelectedLocationIds(
      (prev) =>
        prev.includes(locationId)
          ? prev.filter((id) => id !== locationId) // remove if already selected
          : [...prev, locationId] // add if not selected
    );
  };

  const handleActivateClick = async () => {
    if (selectedLocationIds.length === 0) {
      alert("Please select at least one location to activate");
      return;
    }
    setLoading(true);
    try {
      const response = await SafeLeaseAPI.reputation.syncPortfolioByRelationshipId(relationship.id, relationship.name, selectedLocationIds);
      enqueueSnackbar("Portfolio synced successfully, reload the page to view the created portfolio", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error syncing portfolio", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleCollapseClick = () => {
    setCollapsed((prev) => !prev);
  };

  if (collapsed)
    return (
      <Grid item>
        <SafeLeaseButton onClick={handleCollapseClick}>Portfolio Activation Tools</SafeLeaseButton>
      </Grid>
    );

  return (
    <SafeLeaseCard sx={{ p: 2, height: "100%", position: "relative" }}>
      <Grid container direction="row" justifyContent="space-between" sx={{ height: "100%" }}>
        <Grid item>
          <Stack direction="column" spacing={1}>
            <SafeLeaseButton onClick={handleCollapseClick}>
              <Typography variant="h5">
                {relationship.name} - {relationship.id}
              </Typography>
            </SafeLeaseButton>
          </Stack>
        </Grid>

        <Grid item>
          <Typography variant="h6">Locations ({locations.length})</Typography>
          <Stack direction="column" spacing={1}>
            {locations.map((location: Location) => (
              <Box key={location.id} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedLocationIds.includes(location.id)} onChange={() => handleCheckboxChange(location.id)} />
                  }
                  label={
                    <Typography variant="body2">
                      {location.id} - {location.name} - {location.address}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Stack>
        </Grid>

        <Grid item>
          <Box sx={{ position: "absolute", bottom: 10, left: 25 }}>
            <Typography variant="caption">*activation tools are visible only to admin users</Typography>
          </Box>
          <Box alignContent="bottom">
            {loading ? (
              <CircularProgress />
            ) : (
              <SafeLeaseButton variant="contained" color="primary" onClick={handleActivateClick} disabled={loading}>
                Activate
              </SafeLeaseButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </SafeLeaseCard>
  );
};
