import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseFormTextField } from "@safelease/components";
import GoogleLogo from "../../../../assets/google-logo.png";
import { FormProvider, useForm } from "react-hook-form";
import { AddLocationAltOutlined, KeyboardArrowDown, OpenInNewOutlined } from "@mui/icons-material";
import { useReputation } from "../../../../useReputation";
import { Facility_Reputation as Facility } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useAuth } from "../../../../../auth";
import { reactQueryClient } from "../../../../../utilities/reactQueryClent";
import { AdminsOnly } from "../../../../components/AdminsOnly";

interface IntegrationSettingsProps {}

// Settings for third-party integrations
export function IntegrationSettings({}: IntegrationSettingsProps) {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const { facility, SafeLeaseAPI } = useReputation();
  const form = useForm({
    defaultValues: { googlePlaceId: facility.googlePlaceId },
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  // Update the googlePlaceId of a facility
  const handleGooglePlaceIdSubmit = async (data: Partial<Facility>) => {
    try {
      setSubmitting(true);
      await SafeLeaseAPI.reputation.updateFacilityDetails(facility.id, {
        googlePlaceId: data.googlePlaceId,
        googleReviewLink: `https://search.google.com/local/writereview?placeid=${data.googlePlaceId}`,
      });
      reactQueryClient.invalidateQueries({
        queryKey: ["facilityDetails", facility.id],
      });
      enqueueSnackbar("Google Place ID updated successfully", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to update Google Place ID", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <SafeLeaseCard sx={{ p: 2 }}>
          <Stack direction="column" spacing={2}>
            <img src={GoogleLogo} alt="Google" style={{ width: 60 }} />
            <Typography variant="h5" fontWeight="700" sx={{ my: 1 }}>
              Google
            </Typography>
            <Typography>
              Manage your connection with your Google My Business Profile & links. SafeLease Reputation uses your Google Place to redirect
              tenants to leave reviews on your public Google My Business profile.
            </Typography>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleGooglePlaceIdSubmit)}>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <SafeLeaseFormTextField
                    name="googlePlaceId"
                    label="Google Place ID"
                    disabled={submitting || !isAdmin}
                    placeholder="e.g. ChIJVVXhHQq1RIYRGMu2Cod34gk"
                  />
                  <SafeLeaseButton
                    variant="contained"
                    color="navy"
                    sx={{ width: 260, height: 49 }}
                    endIcon={<OpenInNewOutlined />}
                    disabled={form.watch("googlePlaceId") === "" || submitting}
                    onClick={() =>
                      window.open(`https://search.google.com/local/writereview?placeid=${form.watch("googlePlaceId")}`, "_blank")
                    }
                  >
                    Test Review Link
                  </SafeLeaseButton>
                </Stack>
                <AdminsOnly>
                  <Stack direction="row" justifyContent="flex-end">
                    <SafeLeaseButton color="blue" variant="contained" sx={{ ml: "auto", mt: 2 }} type="submit" disabled={submitting}>
                      Save
                    </SafeLeaseButton>
                  </Stack>
                </AdminsOnly>
                <Alert severity="info" sx={{ mt: 2 }}>
                  Only admins can manage integrations. Please contact a SafeLease administrator to update your Google Place ID.
                </Alert>
              </form>
            </FormProvider>
            <Divider />
            <Box sx={{ minWidth: 700 }}>
              <Accordion elevation={0} sx={{ border: "1px solid", borderColor: "#EBEFF7" }}>
                <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                  <AddLocationAltOutlined sx={{ mr: 1 }} />
                  <Typography>Help me find my Google Place ID</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ mb: 2 }}>
                    Search for your Self Storage Facility <i>using your facility's name, not the address</i> and copy the <b>Place ID</b>{" "}
                    into the field above to connect your business profile.
                  </Typography>
                  <Box sx={{ height: 400 }}>
                    <iframe
                      style={{ height: "100%", width: "100%" }}
                      src="https://geo-devrel-javascript-samples.web.app/samples/places-placeid-finder/app/dist/"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </SafeLeaseCard>
      </Grid>
    </Grid>
  );
}
