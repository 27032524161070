import {
  AdminPanelSettingsOutlined,
  CampaignOutlined,
  FeedbackOutlined,
  KeyboardArrowLeft,
  PersonOutline,
  TrendingUp,
  Tune,
} from "@mui/icons-material";
import { Alert, Box, CircularProgress, MenuItem, Select, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTab, SafeLeaseTabContent, SafeLeaseTabs } from "@safelease/components";
import { useState, useEffect } from "react";
import { FacilityOverview } from "./FacilityViewTabs/FacilityOverview";
import { CampaignsOverview } from "./FacilityViewTabs/CampaignsOverview";
import { ContactsOverview } from "./FacilityViewTabs/ContactsOverview";
import { FacilitySettings } from "./FacilityViewTabs/FacilitySettings";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FeedbackOverview } from "./FacilityViewTabs/FeedbackOverview";
import { useReputation } from "../../useReputation";
import { mixpanelEventHandler } from "../../../utilities/reactMixpanelHandler";
import TitleHeader from "../../../shared/title-header";
import RouterHelper from "../../../utilities/router-helper";
import { useAuth } from "../../../auth";
import { SetupValidationDisplay } from "../../components/SetupValidationDisplay";
import { FacilityAdminTools } from "./FacilityViewTabs/FacilityAdminTools";
import { AdminsOnly } from "../../components/AdminsOnly";

interface FacilityViewProps {
  routerHelper: RouterHelper;
}

export function FacilityView({ routerHelper }: FacilityViewProps) {
  const { statuses, selectedFacilityId, facility, setSelectedFacilityId, portfolio, setSelectedRelationshipId, setReputationRoute } =
    useReputation();
  const [currentTab, setTab] = useState<string>("Overview");
  const { push: navigate } = useHistory();

  // Get relationshipId and facilityId from the URL query parameters
  const { relationshipId: relationshipIdFromUrl, facilityId: facilityIdFromUrl } = useParams<{
    relationshipId?: string;
    facilityId?: string;
  }>();

  // Set the relationshipId and reputationRoute
  // Needed if this page is reloaded or navigated to directly
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const relationshipId = relationshipIdFromUrl ?? auth.user.relationshipId;
  useEffect(() => {
    if (relationshipId) {
      setSelectedRelationshipId(relationshipId);
      setReputationRoute(routerHelper.reputationRoute());
    }
  }, [relationshipId]);

  // Set the selectedFacilityId if the facilityId is in the URL query parameters
  if (facilityIdFromUrl && !selectedFacilityId) {
    setSelectedFacilityId(facilityIdFromUrl);
  }

  useEffect(() => {
    if (facility) {
      mixpanelEventHandler("Reputation - Page View - Facility", { facilityId: facility.id });
    }
  }, [facility]);

  if (!selectedFacilityId) return <Redirect to={routerHelper.reputationRoute()} />;
  if (statuses.facility === "loading" || statuses.portfolio === "loading")
    return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  if (statuses.facility === "error") return <Alert severity="error">Something went wrong. Please refresh the page.</Alert>;

  const settingsTabIcon = <SetupValidationDisplay facility={facility} displayType="icon" fallbackComponent={<Tune sx={{ mr: 1 }} />} />;

  return (
    <Box>
      <TitleHeader title="Facility Overview" />
      <Box sx={{ maxWidth: (theme: Theme) => theme.breakpoints.values.xl }}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <SafeLeaseButton
              variant="text"
              color="navy"
              onClick={() => navigate(routerHelper.reputationRoute())}
              startIcon={<KeyboardArrowLeft />}
              sx={{ fontWeight: 400, fontSize: "16px", fontFamily: "Roboto" }}
            >
              Portfolio
            </SafeLeaseButton>
            <Typography>/</Typography>
            {statuses.portfolio === "loading" && <CircularProgress size={20} />}
            {statuses.portfolio === "success" && (
              <Select
                value={selectedFacilityId}
                onChange={(e) => {
                  setSelectedFacilityId(e.target.value);
                  navigate(`${routerHelper.reputationRoute()}/facility/${e.target.value}`);
                }}
                variant="standard"
                sx={{
                  borderBottom: "none",
                  "&:before": { borderBottom: "none" },
                  "&:hover": { borderBottom: "none", "&:before": { borderBottom: "none" } },
                }}
              >
                {portfolio.facilities.map((facility) => (
                  <MenuItem value={facility.id} key={facility.id}>
                    {`${facility.companyName} - ${facility.address}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
        </Stack>
        <SetupValidationDisplay facility={facility} displayType="fullCard" />

        <SafeLeaseTabs
          onChange={(_, value) => {
            setTab(value);
            mixpanelEventHandler(`Reputation - Tab - ${value}`, { facilityId: facility.id });
          }}
          value={currentTab}
          sx={{ mt: 4 }}
        >
          <SafeLeaseTab label="Overview" icon={<TrendingUp />} value={"Overview"} />
          <SafeLeaseTab label="Campaigns" icon={<CampaignOutlined />} value={"Campaigns"} />
          <SafeLeaseTab label="Private Reviews" icon={<FeedbackOutlined />} value={"Private Reviews"} />
          <SafeLeaseTab label="Contacts" icon={<PersonOutline />} value={"Contacts"} />
          <SafeLeaseTab label="Settings" icon={settingsTabIcon} value={"Settings"} />
          {/* AdminsOnly wrapper component won't work here since the tab must be the child component of SafeLeaseTabs */}
          {isAdmin && <SafeLeaseTab label="Admin Tools" icon={<AdminPanelSettingsOutlined />} value={"Admin Tools"} />}
        </SafeLeaseTabs>
        <Box sx={{ py: 2 }}>
          <SafeLeaseTabContent value={"Overview"} currentTab={currentTab}>
            <FacilityOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Campaigns"} currentTab={currentTab}>
            <CampaignsOverview campaigns={[]} />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Private Reviews"} currentTab={currentTab}>
            <FeedbackOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Contacts"} currentTab={currentTab}>
            <ContactsOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Settings"} currentTab={currentTab}>
            <FacilitySettings />
          </SafeLeaseTabContent>
          <AdminsOnly>
            <SafeLeaseTabContent value={"Admin Tools"} currentTab={currentTab}>
              <FacilityAdminTools />
            </SafeLeaseTabContent>
          </AdminsOnly>
        </Box>
      </Box>
    </Box>
  );
}
