import { QueryResult, useQuery } from "@apollo/client";
import { useState } from "react";
import { Relationship } from "../../../../../utilities/generated/gql-types";
import { getLocations, getRelationship } from "../../../../../queries";
import { useReputation } from "../../../../useReputation";

// TODO: ADD ACTUAL ADMIN TOOLS
export const FacilityAdminTools = () => {
  const { selectedRelationshipId } = useReputation();

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(getRelationship, {
    variables: {
      id: selectedRelationshipId,
    },
    skip: !selectedRelationshipId,
  });

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: {
      relationshipId: selectedRelationshipId,
    },
    skip: !selectedRelationshipId,
  });

  if (relationshipQuery.loading || locationsQuery.loading) {
    return <div>Loading...</div>;
  }

  if (relationshipQuery.error || locationsQuery.error) {
    return <div>Error</div>;
  }

  const relationship = relationshipQuery.data?.getRelationship;
  const locations = locationsQuery.data?.getLocations;

  return <div>visible to safelease admins only</div>;
};
