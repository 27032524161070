import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SetupDataService from "../../services/setup.service";
import { useReportsStore } from "../../reports/useReportsStore";
import {
  Alert,
  Autocomplete,
  AutocompleteChangeReason, Card, Checkbox,
  CircularProgress, FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ghostButtonNavy, inverseNavyButton, muiTextField, navyButton } from "../../styles/mui-overrides";
import DatePicker from "../../components/lib/SafeLeaseDatePicker";
import { useCalendlyEventListener } from "react-calendly";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";
import { QueryResult, useQuery } from "@apollo/client";
import { Relationship } from "../../utilities/generated/gql-types";
import { getRelationship } from "../../queries";
import { useAuth } from "../../auth";
import { CalendlyButton } from "../../shared/CalendlyButton";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import { SafeLeaseFileDropzone } from "@safelease/components";
import { FilePreviews } from "./FilePreviews";
import PrivatePolicyDataService from "../../services/private-policy.service";
import AttachmentDataService from "../../services/attachments.service";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as Sentry from "@sentry/react";

interface Lease {
  id: number;
  unitName: string;
  tenantName: string;
  tenantEmail: string;
};

export function CreatePrivatePolicy() {
  const auth = useAuth();

  const relationshipId = useReportsStore((state) => state.relationshipId);
  const locations = useReportsStore((state) => state.locations);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<any | null>(null);
  const [location, setLocation] = useState<any | null>(null);
  const [leases, setLeases] = useState<Lease[] | null>(null);
  const [lease, setLease] = useState<Lease | null>(null);
  const [isComplianceEnabled, setIsComplianceEnabled] = useState<boolean>(false);
  const [isTenantPolicyExpirationRemindersEnabled, setIsTenantPolicyExpirationRemindersEnabled] = useState<boolean>(false);
  const [unitMatchingMethod, setUnitMatchingMethod] = useState<"unitName" | "moveInDate">("unitName");

  // Private Policy Details
  const [tenantName, setTenantName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [moveInDate, setMoveInDate] = useState<Date | null>(null);
  const [unitName, setUnitName] = useState<string>("");
  const [insuranceCompany, setInsuranceCompany] = useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [policyDeductible, setPolicyDeductible] = useState<string>("");
  const [expiration, setExpiration] = useState<Date | null>(null);
  const [attachment, setAttachment] = useState<File | null>(null);
  const [policyExpirationRemindersEnabled, setPolicyExpirationRemindersEnabled] = useState<boolean>(false);

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> =
    useQuery(getRelationship, {
      variables: {
        id: relationshipId
      },
      skip: !relationshipId
    });

  /* Mixpanel / Calendly events */
  useCalendlyEventListener({
    onDateAndTimeSelected: () =>
      mixpanelEventHandler("Private Policy - Calendly Date Selected", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId
      }),
    onEventTypeViewed: () =>
      mixpanelEventHandler("Private Policy - Calendly Event Type Viewed", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId
      }),
    onEventScheduled: (e) =>
      mixpanelEventHandler("Private Policy - Calendly Event Scheduled", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId
      })
  });

  const onLocationClick = async (selectedLocation) => {
    clearForm();
    if (!selectedLocation) return;

    setLocation(selectedLocation);

    try {
      setIsLoading(true);
      const response = await SetupDataService.getLeasesForLocationId(selectedLocation.id);
      setLeases(response.data.leases);
      setIsComplianceEnabled(response.data.privatePolicyConfig.isComplianceEnabled);
      setIsTenantPolicyExpirationRemindersEnabled(response.data.privatePolicyConfig.isTenantPolicyExpirationRemindersEnabled);
    } catch (error: any) {
      console.error(error);
      setErrors({ other: { msg: "Error fetching leases for location. Please try again." } });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onLeaseClick = async (selectedLease) => {
    setLease(selectedLease);
    if (!selectedLease) return;

    setTenantName(selectedLease.tenantName);
    setEmail(selectedLease.tenantEmail);
    setUnitName(selectedLease.unitName);
  };

  const onSubmitPrivatePolicy = async () => {
    setErrors(null);
    setIsLoading(true);

    let error: any = {};
    if (!location) error.location = { msg: "This information is required." };
    if (Object.keys(error).length > 0) {
      setErrors(error)
      setIsLoading(false);
      return;
    }

    try {
      const privatePolicyData: any = {
        locationId: location.id,
        address: location.address,
        city: location.city,
        state: location.state,
        tenantName,
        email,
        moveInDate: unitMatchingMethod === "moveInDate" ? moveInDate : undefined,
        unitName: unitMatchingMethod === "unitName" ? unitName : undefined,
        insuranceCompany: insuranceCompany || null,
        policyNumber,
        policyDeductible: policyDeductible ? Number(policyDeductible.replace(/\D/g, "")) : null,
        expiration: expiration ? dayjs(expiration).format("YYYY-MM-DD") : null,
        attachment,
        policyExpirationRemindersEnabled
      };

      if (attachment) {
        // TODO: We should validate the file type before uploading
        const isValidAttachment = true;
        if (isValidAttachment) {
          privatePolicyData.attachment_filename = await AttachmentDataService.directUpload(attachment);
        }

        privatePolicyData.attachment_desired_filename = attachment.name;
        privatePolicyData.attachment_mime_type = attachment.type;
        privatePolicyData.isValidFile = isValidAttachment.toString();
      }
      const response = await PrivatePolicyDataService.save(privatePolicyData);
      if (response.data.errors) {
        setErrors(response.data.errors);
      } else {
        setIsSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setErrors({ other: { msg: "Error submitting form. Please try again." } });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onNewFiles = (files: File[]) => {
    if (files.length === 0) {
      setErrors({ attachment: { msg: "File is required." } });
    } else if (files.length > 1) {
      setErrors({ attachment: { msg: "Only one file can be uploaded." } });
    } else {
      setAttachment(files[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const clearForm = () => {
    setIsSuccess(false);
    setErrors(null);
    setLocation(null);
    setLeases(null);
    setLease(null);
    setIsComplianceEnabled(false);
    setIsTenantPolicyExpirationRemindersEnabled(false);
    setUnitMatchingMethod("unitName");

    // Private Policy Details
    setTenantName("");
    setEmail("");
    setMoveInDate(null);
    setUnitName("");
    setInsuranceCompany("");
    setPolicyNumber("");
    setPolicyDeductible("");
    setExpiration(null);
    setAttachment(null);
    setPolicyExpirationRemindersEnabled(false);
  };

  return (
    <Fragment>
      <Button onClick={() => setOpen(true)} sx={{ ...ghostButtonNavy, height: "2.5rem" }}>
        Upload Private Policy
      </Button>
      {open && (
        <Dialog
          open={true}
          onClose={handleClose}
          PaperProps={{
            sx: { minWidth: { xs: 300, sm: 600 } }
          }}
        >
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Uploading private policy for tenants</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {isSuccess ? (
              <>
                <Stack direction="row" justifyContent="center" width={"100%"} mb={4}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    alignSelf="center"
                    textAlign="center"
                    spacing={1}
                    sx={{
                      height: "100%",
                      width: "80%"
                    }}
                  >
                    <CheckCircleIcon color={"success"} sx={{ fontSize: "60px" }} />
                    <Typography variant="h6">Successfully created a private policy for {unitMatchingMethod === "unitName" ? `unit ${unitName}` : `${tenantName}`}.</Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#929292"
                      }}
                    >
                      This may take a few minutes to process.{email && `A confirmation email will be sent to ${tenantName} at ${email}.`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="center" width={"100%"}>
                  <Button sx={navyButton} onClick={clearForm}>Submit another private policy</Button>
                </Stack>
              </>
            ) : (
              <Stack direction="column" spacing={2}>
                <Autocomplete
                  value={location}
                  options={locations}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  getOptionLabel={(option) => option.address}
                  onChange={(event, value, reason: AutocompleteChangeReason) => onLocationClick(reason === "clear" ? null : value)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      sx={{ mt: 1 }}
                      error={!!errors?.locationId}
                      helperText={errors?.locationId ? errors.locationId.msg : ""}
                      label={location ? "Facility" : "Select facility"}
                    />
                  }
                />
                {!!leases && (
                  <>
                    {leases.length > 0 ? (
                        <>
                          {unitMatchingMethod === "unitName" ? (
                            <Autocomplete
                              value={lease}
                              options={leases}
                              isOptionEqualToValue={(option, value) => option.id === value?.id}
                              getOptionLabel={(option) => `Unit ${option.unitName} - ${option.tenantName}`}
                              onChange={(event, value, reason: AutocompleteChangeReason) => onLeaseClick(reason === "clear" ? null : value)}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  error={!!errors?.unitName}
                                  helperText={errors?.unitName ? errors.unitName.msg : ""}
                                  label={lease ? "Unit" : "Select unit"}
                                />
                              }
                          />
                          ) : (
                            <DatePicker
                              label="Move-in date"
                              value={moveInDate}
                              onChange={(date) => setMoveInDate(dayjs(date).toDate())}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!errors?.moveInDate,
                                  helperText: errors?.moveInDate ? errors.moveInDate.msg : "",
                                }
                              }}
                            />
                          )}
                          <Typography
                            onClick={() => setUnitMatchingMethod(unitMatchingMethod === "unitName" ? "moveInDate" : "unitName")}
                            sx={{
                              color: (theme) => theme.palette.grey[600],
                              cursor: "pointer",
                              fontSize: 14,
                              fontWeight: 500,
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            {unitMatchingMethod === "unitName" ? "Can't find the unit you're looking for? Use move-in date." : "Don't know your move-in date? Use unit/space number."}
                          </Typography>
                          {((unitMatchingMethod === "unitName" && !!lease) || (unitMatchingMethod === "moveInDate" && !!moveInDate && moveInDate.toString() !== "Invalid Date")) && (
                            <>
                              <Typography variant="subtitle1" gutterBottom>
                                Tenant's private policy details
                              </Typography>
                              <Grid container>
                                <Grid item container spacing={{ xs: 0, sm: 2 }} mb={2}>
                                  <Grid item container xs={12} sm={6}>
                                    <TextField
                                      value={tenantName}
                                      onChange={(e) => setTenantName(e.target.value)}
                                      label="Tenant name"
                                      error={!!errors?.tenantName}
                                      helperText={errors?.tenantName ? errors.tenantName.msg : ""}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item container xs={12} sm={6}>
                                    <TextField
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      label="Tenant email"
                                      error={!!errors?.email}
                                      helperText={errors?.email ? errors.email.msg : ""}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item container spacing={{ xs: 0, sm: 2 }} mb={2}>
                                  <Grid item container xs={12} sm={6}>
                                    <TextField
                                      value={insuranceCompany}
                                      onChange={(e) => setInsuranceCompany(e.target.value)}
                                      label="Insurance company (optional)"
                                      error={!!errors?.insuranceCompany}
                                      helperText={errors?.insuranceCompany ? errors.insuranceCompany.msg : ""}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item container xs={12} sm={6}>
                                    <TextField
                                      value={policyNumber}
                                      onChange={(e) => setPolicyNumber(e.target.value)}
                                      label="Policy number"
                                      error={!!errors?.policyNumber}
                                      helperText={errors?.policyNumber ? errors.policyNumber.msg : ""}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item container spacing={{ xs: 0, sm: 2 }} mb={2}>
                                  <Grid item container xs={12} sm={6}>
                                    <NumericFormat
                                      value={policyDeductible}
                                      onChange={(e) => setPolicyDeductible(e.target.value)}
                                      allowNegative={false}
                                      fixedDecimalScale
                                      thousandSeparator=","
                                      prefix="$"
                                      decimalScale={2}
                                      fullWidth
                                      error={!!errors?.policyDeductible}
                                      label="Policy Deductible (optional)"
                                      customInput={TextField}
                                    />
                                    {errors?.policyDeductible && (
                                      <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                                        {errors.policyDeductible.msg}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item container xs={12} sm={6}>
                                    <DatePicker
                                      label="Expiration date"
                                      value={expiration}
                                      onChange={(date) => setExpiration(dayjs(date).toDate())}
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          error: !!errors?.expiration,
                                          helperText: errors?.expiration ? errors.expiration.msg : "",
                                          sx: {
                                            ...muiTextField,
                                            marginBottom: 0
                                          }
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                  <Card sx={{ borderRadius: 1, width: "100%", mt: 1 }}>
                                    {attachment ? (
                                      <FilePreviews files={[attachment]} deleteFile={() => setAttachment(null)} />
                                    ) : (
                                      <SafeLeaseFileDropzone
                                        containerProps={{
                                          sx: {
                                            borderRadius: 1,
                                            backgroundColor: "#757575",
                                            "&:hover": {
                                              cursor: "pointer"
                                            }
                                          }
                                        }}
                                        uploadButtonProps={{
                                          sx: {
                                            ...inverseNavyButton,
                                            backgroundColor: "white",
                                            "&:hover": {
                                              backgroundColor: "#939ba2"
                                            }
                                          }
                                        }}
                                        accept={{
                                          "application/pdf": [".pdf"],
                                          "image/jpeg": [".jpeg", ".jpg"],
                                          "image/png": [".png"],
                                          "application/msword": [".doc", ".docx"],
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
                                        }}
                                        handleNewFiles={(files) => onNewFiles(files)}
                                      />
                                    )}
                                  </Card>
                                  {errors?.attachment && (
                                    <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                                      {errors.attachment.msg}
                                    </Typography>
                                  )}
                                </Grid>
                                {isComplianceEnabled && isTenantPolicyExpirationRemindersEnabled && (
                                  <Grid item container xs={12} mt={1}>
                                    <FormControlLabel
                                      label="Remind tenant 30 days before their policy will expire via email."
                                      control={
                                        <Checkbox
                                          checked={policyExpirationRemindersEnabled}
                                          onChange={(e) => setPolicyExpirationRemindersEnabled(e.target.checked)}
                                        />
                                      }
                                    />
                                  </Grid>
                                )}
                                {!isComplianceEnabled && (
                                  <Grid item container justifyContent="center" xs={12} mt={2}>
                                    <CalendlyButton
                                      relationshipId={relationshipId}
                                      label="Want to send 30 day policy expiration notices? Talk to your rep."
                                      linkName="privatePolicy"
                                      hideEndIcon
                                      sx={{
                                        border: "none",
                                        fontWeight: "500"
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}
                        </>
                      ) :
                      <Typography p={1}>No leases</Typography>
                    }
                  </>
                )}
              </Stack>
            )}
          </DialogContent>
          {!isSuccess && (
            <DialogActions>
              <Stack direction="column" justifyContent="center" width={"100%"}>
                {isLoading && (
                  <Stack direction="row" justifyContent="center" width={"100%"} mb={1}>
                    <CircularProgress />
                  </Stack>
                )}
                {!!lease && (
                  <>
                    {!isLoading && (
                      <Stack direction="row" justifyContent="center" flexGrow={1} mb={1} mx={2}>
                        <Button sx={navyButton} onClick={onSubmitPrivatePolicy} fullWidth>Submit private policy</Button>
                      </Stack>
                    )}
                  </>
                )}
                {!!errors?.other?.msg && (
                  <Stack direction="row" justifyContent="center" width={"100%"} mb={1}>
                    <Alert severity="warning" color="error">
                      {errors.other.msg}
                    </Alert>
                  </Stack>
                )}
              </Stack>
            </DialogActions>
          )}
        </Dialog>
      )}
    </Fragment>
  )
    ;
}


